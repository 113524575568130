import {
  DURATION_OPTIONS,
  LEVEL_OF_CHANGE_OPTIONS,
  MOVEMENT_OPTIONS,
  PEOPLE_IMPACTED_OPTIONS,
  TYPE_OF_INITIATIVE_OPTIONS
} from '@/pages/ReportForm/constants/options'

export const FIELD_NAME = {
  country: 'country',
  siteOrCountry: 'siteOrCountry',
  title: 'title',
  description: 'description',
  report_period: 'report_period',
  partner: 'partner',
  people_impacted: 'people_impacted',
  people_impacted_comment: 'people_impacted_comment',
  people_supported_count: 'people_supported_count',
  movement_supported: 'movement_supported',
  type_of_initiative: 'type_of_initiative',
  type_of_initiative_comment: 'type_of_initiative_comment',
  spent: 'spent',
  level: 'level',
  duration: 'duration',
  coworkers_count: 'coworkers_count',
  coworker_hours: 'coworker_hours',
  customers_count: 'customers_count',
  comment: 'comment'
}

export const FIELD_OPTIONS = {
  [FIELD_NAME.people_impacted]: PEOPLE_IMPACTED_OPTIONS,
  [FIELD_NAME.movement_supported]: MOVEMENT_OPTIONS,
  [FIELD_NAME.type_of_initiative]: TYPE_OF_INITIATIVE_OPTIONS,
  [FIELD_NAME.level]: LEVEL_OF_CHANGE_OPTIONS,
  [FIELD_NAME.duration]: DURATION_OPTIONS
}

export const SUBMIT_TYPES = ['draft', 'submitted', 'global_review']
